@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

*::selection {
	background-color: gold;
	color: black;
	scroll-behavior: smooth;
}

main {
	background-color: #fffbdf;
}

.formularz {
	background: radial-gradient(
			circle at 50% 100%,
			#e7d8a280 5%,
			#e7d8a2 5% 10%,
			#e7d8a280 10% 15%,
			#e7d8a2 15% 20%,
			#e7d8a280 20% 25%,
			#e7d8a2 25% 30%,
			#e7d8a280 30% 35%,
			#e7d8a2 35% 40%,
			transparent 40%
		),
		radial-gradient(
			circle at 100% 50%,
			#e7d8a280 5%,
			#e7d8a2 5% 10%,
			#e7d8a280 10% 15%,
			#e7d8a2 15% 20%,
			#e7d8a280 20% 25%,
			#e7d8a2 25% 30%,
			#e7d8a280 30% 35%,
			#e7d8a2 35% 40%,
			transparent 40%
		),
		radial-gradient(
			circle at 50% 0%,
			#e7d8a280 5%,
			#e7d8a2 5% 10%,
			#e7d8a280 10% 15%,
			#e7d8a2 15% 20%,
			#e7d8a280 20% 25%,
			#e7d8a2 25% 30%,
			#e7d8a280 30% 35%,
			#e7d8a2 35% 40%,
			transparent 40%
		),
		radial-gradient(
			circle at 0 50%,
			#e7d8a280 5%,
			#e7d8a2 5% 10%,
			#e7d8a280 10% 15%,
			#e7d8a2 15% 20%,
			#e7d8a280 20% 25%,
			#e7d8a2 25% 30%,
			#e7d8a280 30% 35%,
			#e7d8a2 35% 40%,
			transparent 40%
		);
	background-size: 3em 3em;
	background-color: #ffffff;
	opacity: 1;
}

.button-primary {
	margin-left: 50%;
	transform: translateX(-50%);
	background-color: yellow;
	color: black;
	font-weight: bold;
}

.social-icon {
	font-size: 50px;
}

.footer {
	background-color: #fcf5bb;
}

.admin-nav {
	/* text-decoration: underline red; */
	border: dotted red 1px;
	border-radius: 10%;
}

.category-item {
	transition: scale 0.3s;
}

.category-item:hover {
	scale: 1.05;
}

.upload-active {
	background: radial-gradient(
			circle at 50% 100%,
			#7cff8d80 5%,
			#7cff8d 5% 10%,
			#7cff8d80 10% 15%,
			#7cff8d 15% 20%,
			#7cff8d80 20% 25%,
			#7cff8d 25% 30%,
			#7cff8d80 30% 35%,
			#7cff8d 35% 40%,
			transparent 40%
		),
		radial-gradient(
			circle at 100% 50%,
			#7cff8d80 5%,
			#7cff8d 5% 10%,
			#7cff8d80 10% 15%,
			#7cff8d 15% 20%,
			#7cff8d80 20% 25%,
			#7cff8d 25% 30%,
			#7cff8d80 30% 35%,
			#7cff8d 35% 40%,
			transparent 40%
		),
		radial-gradient(
			circle at 50% 0%,
			#7cff8d80 5%,
			#7cff8d 5% 10%,
			#7cff8d80 10% 15%,
			#7cff8d 15% 20%,
			#7cff8d80 20% 25%,
			#7cff8d 25% 30%,
			#7cff8d80 30% 35%,
			#7cff8d 35% 40%,
			transparent 40%
		),
		radial-gradient(
			circle at 0 50%,
			#7cff8d80 5%,
			#7cff8d 5% 10%,
			#7cff8d80 10% 15%,
			#7cff8d 15% 20%,
			#7cff8d80 20% 25%,
			#7cff8d 25% 30%,
			#7cff8d80 30% 35%,
			#7cff8d 35% 40%,
			transparent 40%
		);
	background-size: 3em 3em;
	background-color: #ffffff;
	opacity: 1;
}

.com {
	width: 100%;
	border-radius: 20px;
}

.subkomentarzebox {
	position: relative;
}

.reply-btn {
	background-color: greenyellow;
	color: black;
	font-weight: bold;
	font-size: 12px;
	border-radius: 15px;
	max-height: 40px;
	position: absolute;
	left: 90%;
	bottom: 10px;
	transform: translateX(-30%);
}

.edit-btn {
	color: black;
	font-weight: bold;
	font-size: large;
}

.edit-btn:hover {
	color: #ff0000;
}

.save-button {
	background-color: rgb(25, 167, 0);
	color: black;
	font-weight: bold;
}

*::-webkit-scrollbar {
	width: 15px;
}

*::-webkit-scrollbar-thumb {
	border-radius: 5px;
	background-color: #d8ad00;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: #a35400;
}

*::-webkit-scrollbar-track {
	border-radius: 5px;
	background-color: #fffbdf;
}

.myposts-bg {
	background: #fff6bd;
	background: repeating-radial-gradient(
		circle farthest-corner at center center,
		#fff6bd 95%,
		#fff8d4 100%
	);
	min-height: 20vh;
}

.delete-btn {
	background-color: rgb(255, 0, 0);
	color: black;
	font-weight: bold;
}

.edit-btn2 {
	background-color: yellow;
	color: black;
	font-weight: bold;
}

.card-my {
	position: relative;
	z-index: 1;
}

.centering {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	display: inline-block;
	text-align: center;
}

.liked_img {
	max-height: 120px;
}

.liked {
	max-height: 200px;
}

.content-sub {
	float: left;
}

.subcomment {
	position: relative;
	background-color: #f9fbb9;
	border-radius: 20px;
	padding: 4px;
	border: 1px solid rgb(252, 200, 200);
}

.sub-container {
	width: 95%;
	margin-left: 10%;
}

.subcomment-form {
	width: 75%;
	margin-left: 20%;
}

.message_subc {
	width: 75%;
	margin-left: 20%;
}

.edit-btn-sub {
	position: absolute;
	right: 5%;
}

.content-wrapper {
	max-width: 100%;
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-word;
}

.sort-btn {
	background: gray;
}

.dropdown-item:hover {
	background: gray;
}

.sort {
	display: flex;
	align-items: right;
	justify-content: right;
}

.logoo {
	width: 50px;
	height: 50px;
	transition: scale 0.3s;
}

.logoo:hover {
	scale: 1.2;
}

.my-masonry-grid {
	display: flex;
	width: auto;
}

.my-masonry-grid_column {
	padding-left: 10px; /* gutter size */
	background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
	margin-bottom: 30px;
}

@media (max-width: 1201px) {
	.categories {
		display: none;
	}
}

.button-categorieslist {
	float: right;
	background-color: yellow;
	color: black;
	font-weight: bold;
	padding: 3px;
	border-radius: 25px;
}

.app {
	min-height: 100vh;
}

@media (max-width: 769px) {
	.lajki {
		display: flex;
		justify-content: center;
		margin-bottom: 15px;
	}
}

.rozwijanie {
	margin-left: 50%;
}

/* * {
	border: 1px solid red;
} */

.akordeon_body {
	background-color: rgb(255, 253, 182);
}

.akordeon_header {
	padding: 10px 0px;
}

.url_address {
	display: none;
}

.ramka {
	border: 2px solid #ff4d00; 
	border-radius: 20px; 
	box-shadow: 0 0 20px rgb(255, 106, 0); 
}

.top-views {
	border: 2px solid #3300ff; 
	border-radius: 20px; 
	box-shadow: 0 0 20px rgb(81, 0, 255); 
}
